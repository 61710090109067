import React, { useEffect, useState } from 'react'
import LocalsTable from './../components/table/LocalsTable'
import CardStats from './../components/CardStats'
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl'
import Loader from './../components/loader/Loader';
import { useSelector, useDispatch } from 'react-redux'

let countLocals;
const Locals = (props) => {
    let [load, setLoad] = useState(0);
    let [loader, setLoader] = useState(0);
    let [checkTable, setCheckTable] = useState(0);
    let lang = useSelector(state => state.language);

    useEffect(() => {
        if (load == 0 && !countLocals) {
            ;(async function() {
                await localsList();
                await setLoad(1);
            })()
        }
    },[load]);




    const localsList = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/countLocalities`,
        }).then(response => {
            countLocals = response.request.response;
        });
    }

    const goToPage = (page) => {
        props.history.push(`/dashboard/localities/${page}`)
    }

    return (
        <>  
            <div className="container-fluid">
                <div className="row">
                    <CardStats title="Wszystkich miejscowości" 
                                data={countLocals} 
                                icon="home"
                                desc={ false }
                                column="sm-3" />
                </div>
                <div className="row" style={{ paddingBottom: "10px" }}>
                    <div className="col-sm-3" onClick={ () => goToPage('new') }>
                        <div className="btn">
                            <i className="material-icons">add</i>
                            Dodaj nową miejscowość
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3" onClick={ () => goToPage('group-languages') }>
                        <div className="btn">
                            <i className="material-icons">add</i>
                            Grupy językowe
                        </div>
                    </div>
                </div>
                <div className="row">
                    <LocalsTable col="xs-12" lang={lang} This={props} checkTableFunction={ (status) => setCheckTable(status) }/>
                </div>
            </div>
        </>        
    );
}

export default Locals;



