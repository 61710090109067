import React, { useState, useEffect } from 'react'
import VrUrl from './../../../services/VrUrl';

const VrRepeater = ( props ) => {
    let [ load, setLoad ] = useState(0);
    let [ data, setData ] = useState('');
    let [ remove, setRemove ] = useState(false);
    let newArray = [];
    useEffect(() => {
        
        if(load === 0) {
            createObject();
            setLoad(1);
        }

    }, [load])

    const createObject = () => {
        newArray = props.showNewInputs;
        console.log(props.showNewInputs);
        newArray[props.number] = {                    
            id: props.data.id ? props.data.id : 0,
            name: props.data.name ? props.data.name : '',
            remove: remove
        }
        console.log(newArray);
        props.setNewInputs(newArray);
    }

    useEffect(() => {
        if(load) {
            createObject();
        }
    }, [data, remove])

    return (
        <>
        {!remove &&
        <div className="image-repeater-wrapper">
            <div className="vr-row">
                <div className="remove-vr"><span onClick={ () => setRemove(true) }>Usuń</span></div>
                <div className="items">
                    <iframe id="inlineFrameExample"
                        title="vr"
                        width="100%"
                        height="600"
                        src={`${VrUrl()}?${props.type}=${props.dataID}&srcLoad=${props.data.id ? props.data.id : 0}&token=${localStorage.getItem('token')}`}>
                    </iframe>
                </div>
            </div>
        </div>  
        }
        </>
    );
}

export default VrRepeater;