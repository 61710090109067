import React, { useEffect, useState } from 'react'
import AtractionsTable from './../components/table/AtractionsTable'
import CardStats from './../components/CardStats'
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl'
import { useSelector, useDispatch } from 'react-redux'

let countAtractions;
const Atractions = (props) => {
    let [load, setLoad] = useState(0);
    let lang = useSelector(state => state.language);

    useEffect(() => {
        if (load == 0 && !countAtractions) {
            ;(async function() {
                await localsList();
                await setLoad(1);
            })()
        }
    },[load]);




    const localsList = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/countAtractions`,
        }).then(response => {
            countAtractions = response.request.response;
        });
    }

    const goToPage = (page) => {
        props.history.push(`/dashboard/atractions/${page}`)
    }

    return (
        <>  
            <div className="container-fluid">
                <div className="row">
                    <CardStats title="Wszystkich atrakcji" 
                                data={countAtractions} 
                                icon="home"
                                desc={ false }
                                column="sm-3" />
                </div>
                <div className="row" style={{ paddingBottom: "10px" }}>
                    <div className="col-sm-3" onClick={ () => goToPage('new') }>
                        <div className="btn">
                            <i className="material-icons">add</i>
                            Dodaj nową atrakcję
                        </div>
                    </div>
                </div>
                <div className="row">
                    <AtractionsTable col="xs-12" This={props} lang={lang} />
                </div>
            </div>
        </>        
    );
}

export default Atractions;



