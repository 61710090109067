import checkSession from './checkSession';
import actualDate from './actualDate';
import logout from './../user/logout';

const redirectSession = (props) => {
    console.log(props)
    let now = actualDate();
    let sessionDate = localStorage.getItem('sessionDate');
    let checkStatusSession = checkSession(sessionDate, now, 60);
    console.log(checkStatusSession)
    if(sessionDate) {
        if(checkStatusSession === false) {
            logout(props);
        }else{
            localStorage.setItem('sessionDate', now);
        }
    }
}

export default redirectSession;