import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
// import CustomPagination from './customPagination';

let results = [];
let lang = 'PL';
export default class LocalsTable extends Component {
    async componentDidMount() {
      if(results.length === 0) {
        await this.props.checkTableFunction(1);
        await this.getLocals();
        await this.props.checkTableFunction(0);
        lang = this.props.lang;
      }else{
        this.setState({
          locals: results
        })
      } 
    }

    async componentDidUpdate() {
      if(this.props.lang !== lang) {
        lang = this.props.lang;
        await this.props.checkTableFunction(1);
        await this.getLocals();
        await this.props.checkTableFunction(0);
      }
    }

    constructor(props) {
      super(props)

      this.state = {
        locals: [],
      }
    }

    async getLocals() {
      this.setState({
        locals: []
      })
      await axios({
          method: 'post',
          url: `${ApiUrl()}/localityList`,
          data: {
            token: localStorage.getItem('token'),
            access_token: localStorage.getItem('token'),
            lang: this.props.lang
          }
      }).then(response => {
          results = response.data.data;
      });
      results.map(local => {
        this.setState(prevState => ({
          locals: [...prevState.locals, { 
            id                : local['id'],
            main_image_id     : local['main_image_id'] !== null ? local['main_image_id']['src'] : null,
            name              : local['name'],
            languageGp        : local['group_languages_id']['name'],
            description       : local['description'],
            shortDescription  : local['short_description'],
            map_lat           : local['map_lat'],
            map_lng           : local['map_lng'],
            user_id           : local['user_id'],
            created_at        : local['created_at'],
          }]
        }))
      })
    }

    render() {
        const columns = [
        {
          Header: 'ID',
          accessor: 'id'
        },
        {
          Header: 'Główne zdjęcie',
          // accessor: 'main_image_id',
          // Cell: props => <img src={ `data:image/jpeg;base64,${props.original.main_image_id}` } />,
          Cell: props => <img src={ props.original.main_image_id } />,
        },
        {
          Header: 'Nazwa',
          accessor: 'name',
        },
        {
          Header: 'Grupa językowa',
          accessor: 'languageGp',
        },
        {
          Header: 'Opis',
          accessor: 'shortDescription',
        },
        {
          Header: 'Mapa koordynat LAT',
          accessor: 'map_lat',
        },
        {
          Header: 'Mapa koordynat LNG',
          accessor: 'map_lng',
        },
        {
          Header: 'Dodał',
          Cell: props => <div> { props.original.user_id.login } </div>,
        }   
      ]
        return (
          <div className={`column col-${ this.props.col }`}>
            <ReactTable
              data={this.state.locals}
              columns={columns}
              sortable={false}
              getTrProps={(state, rowInfo) => ({
                onClick: () => this.props.This.history.push("localities/city/" + rowInfo.original.id)
              })}
              showPageSizeOptions={false}
              defaultPageSize={20}
              showPagination={true}
              filterable={true}
            />
            {/* <CustomPagination /> */}
          </div>
        )
    }
}