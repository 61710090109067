import React, { useState, useEffect } from 'react'

const NewSliderInput = ( props ) => {
    let [ headerPl, setHeaderPl ] = useState();
    let [ headerCz, setHeaderCz ] = useState();
    let [ descriptionPl, setDesciptionPl ] = useState();
    let [ descriptionCz, setDesciptionCz ] = useState();
    let newArray = [];
    let [ load, setLoad ] = useState(0);

    useEffect(() => {
        if(load === 0) {
            setHeaderPl(props.data.header_pl ? props.data.header_pl : '');
            setHeaderCz(props.data.header_cz ? props.data.header_cz : '');
            setDesciptionPl(props.data.description_pl ? props.data.description_pl : '');
            setDesciptionCz(props.data.description_cz ? props.data.description_cz : '');
            setLoad(1)
        }
    }, [load])

    const createObject = () => {
        newArray = props.showNewInputs;
        newArray[props.number] = {                    
            header_pl: headerPl,
            header_cz: headerCz,
            description_pl: descriptionPl,
            description_cz: descriptionCz,
            id: props.data.id ? props.data.id : 0,
        }
        props.setNewInputs(newArray);
    }
    
    return (
        <div className="input-wrapper">
            <div className="number">
                <span>{ props.number +1 }</span>
            </div>
            <div className="box">
                <div className="item">
                    <label>Nagłówek PL</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setHeaderPl(e.target.value) } value={ headerPl } />
                </div>
                <div className="item">
                    <label>Nagłówek CZ</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setHeaderCz(e.target.value) } value={ headerCz } />
                </div>
            </div>
            <div className="box">
                <div className="item">
                    <label>Tekst PL</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setDesciptionPl(e.target.value) } value={ descriptionPl } />
                </div>
                <div className="item">
                    <label>Tekst CZ</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setDesciptionCz(e.target.value) } value={ descriptionCz } />
                </div>
            </div>
        </div> 
    );
}

export default NewSliderInput;
