import React, { useState, useEffect } from 'react'
import Input from './../components/Input';
import Textarea from './../components/Textarea';
import Select from './../components/Select';

const ImageRepeater = ( props ) => {
    let [ load, setLoad ] = useState(0);
    let [ data, setData ] = useState('');
    let [ err, setErr ] = useState(false);
    let [ name, setName ] = useState('');
    let [ visible, setVisible ] = useState(1);
    let [ description, setDescription] = useState('');
    let [ update, setUpdate ] = useState(false);
    let newArray = [];
    useEffect(() => {
        
        if(load === 0) {
            console.log(props.data)
            setData(props.data.file ? props.data.file : '');
            setName(props.data.name);
            setDescription(props.data.description);
            setVisible(props.data.visible);
            setLoad(1);
        }

    }, [load])

    const createObject = () => {
        newArray = props.showNewInputs;
        if(!props.withData) {
            newArray[props.number] = {                    
                file: data,
                id: props.data.id ? props.data.id : 0,
            }
        }else{
            newArray[props.number] = {                    
                file: data,
                id: props.data.id ? props.data.id : 0,
                name: name,
                description: description,
                visible: visible ? visible : 1,
                update: update
            }
        }
        props.setNewInputs(newArray);
    }

    useEffect(() => {
        if(load) {
            createObject();
        }
    }, [data, name, description, update, visible])

    const onDrop = e => {
        e.preventDefault();
        const {
          dataTransfer: { files }
        } = e;
        const { length } = files;
        const reader = new FileReader();
        if (length === 0) {
          return false;
        }
        const fileTypes = ["image/jpeg", "image/jpg", "image/png"];
        const { size, type } = files[0];
        setData('');
        if (!fileTypes.includes(type)) {
          setErr("File format must be either png or jpg");
          return false;
        }
        if (size / 1024 / 1024 > 2) {
          setErr("File size exceeded the limit of 2MB");
          return false;
        }
        setErr(false);
    
        reader.readAsDataURL(files[0]);
        reader.onload = loadEvt => {
          setData(loadEvt.target.result);
          setUpdate(true)
        };
    };

    const onDragOver = e => {
    e.preventDefault();
    };

    let optionSelect = [
        {
            value: 1,
            text: "pokaż"
        }, 
        {
            value: 0,
            text: "ukryj"
        }
    ]
    return (
        <div className="image-repeater-wrapper">
            <div className="box-row">
                <div className="files">
                    <div className="drop-area">
                        {err && <p>{err}</p>}
                        <div
                            className="place"
                            onDrop={e => onDrop(e)}
                            onDragOver={e => onDragOver(e)}
                        >
                            {data !== '' && <img src={data} />}
                        </div>
                        <div className="button-wrapper">
                            {data !== '' && <button onClick={() => setData('')}>Usuń</button>}
                        </div>
                    </div>
                    {props.withData &&
                        <div className="inputs">
                            <div className="row">
                                <Input name="name"
                                    label="Nazwa zdjęcia"
                                    type="text" 
                                    value={ name }
                                    disabled={ false } 
                                    handle={ (val) => { setName(val); setUpdate(true) } } 
                                    error={null}
                                    column="xs-12" />
                            </div>
                            <div className="row">
                                <Textarea name="description"
                                    label="Opis zdjęcia"
                                    value={ description }
                                    disabled={ false } 
                                    handle={ (val) => { setDescription(val); setUpdate(true) } } 
                                    error={ null }
                                    column="xs-12" />
                            </div>
                            <div className="row">
                                <Select name="lng"
                                    label="Wyświetl na liście"
                                    data={ optionSelect } 
                                    value={ visible }
                                    handle={ (val) => { setVisible(val); setUpdate(true) } } 
                                    column="xs-12" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>  
    );
}

export default ImageRepeater;
