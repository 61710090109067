import React, { useState } from 'react'

const Textarea = ( props ) => {
    return (
        <div className={ `col-${ props.column } textarea-wrapper` } >
            <div className="form-group bmd-form-group">
                <label className={props.error ? "bmd-label-floating error" : "bmd-label-floating"} for={ props.name } > { props.label } </label>
                {/* <input className="form-control" type={ props.type } name={ props.name }  disabled={ props.disabled ? 'disabled' : null} value={ props.value } onInput={ (e) => props.handle(e.target.value) }/> */}
                <textarea className="form-control" disabled={ props.disabled ? 'disabled' : null} name={ props.name } value={props.value} onInput={ (e) => props.handle(e.target.value) }></textarea>
            </div>
        </div>   
    );
}

export default Textarea;
