import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
// import CustomPagination from './customPagination';

let results = [];
let updateGroups = false;
export default class GroupLanguagesTable extends Component {
    async componentDidMount() {
      if(results.length === 0) {
        await this.getGroups();
      }else{
        this.setState({
          groups: results
        })
      } 
    }

    constructor(props) {
      super(props)

      this.state = {
        groups: [],
        removeWindow: 0,
        removeData: [],
        removeIndex: 0,
      }
    }

    async componentDidUpdate() {
        if(this.props.newGroup !== updateGroups) {
            console.log(this.props.newGroup)
            let newGroups = this.state.groups;
            console.log(newGroups)
            newGroups = [...newGroups, this.props.newGroup];
            this.setState({
                groups: newGroups
            })
            await this.props.setNewGroup();
            this.props.saveGroups(newGroups);
        }
    }

    async getGroups() {
        this.setState({
            groups: []
        })
        await axios({
            method: 'post',
            url: `${ApiUrl()}/getLanguagesGroup`,
            data: {
                token: localStorage.getItem('token'),
                access_token: localStorage.getItem('token'),
            }
        }).then(response => {
            console.log(results)
            results = response.data.data;
        });
        this.props.saveGroups(results);
        results.map(group => {
            this.setState(prevState => ({
                groups: [...prevState.groups, { 
                id                : group['id'],
                name              : group['name'],
                user_id           : group['user_id'],
                locality          : group['locality'],
                created_at        : group['created_at'],
            }]
            }))
        })
    }

    async removeGroup() {
        let groupID = this.state.removeData.id;
        await axios({
            method: 'post',
            url: `${ApiUrl()}/removeLanguagesGroup`,
            data: {
              token: localStorage.getItem('token'),
              groupID: groupID
            }
        }).then(response => {
            let newGroups = this.state.groups;
            newGroups = newGroups.filter((group) => group.id !== this.state.removeData.id)
            this.setState({
                removeWindow: 0,
                removeIndex: 0,
                removeData: [],
                groups: newGroups
            })
            this.props.saveGroups(newGroups);
        });
    }

    render() {
        const columns = [
        {
          Header: 'ID',
          accessor: 'id'
        },
        {
          Header: 'Nazwa',
          accessor: 'name',
        },
        {
          Header: 'Lokale',
        Cell: props =>  props.original.locality && props.original.locality.map((local, i) => ( <div style={{ cursor: 'pointer', display: 'block' }} key={i} onClick={() => this.props.This.history.push("/dashboard/localities/city/" + local.id)}>{local.name}{(props.original.locality).length > (i+1) ? ", " : "" }</div>)),
        },
        {
          Header: 'Dodał',
          Cell: props => <div> { props.original.user_id.login } </div>,
        },  
        {
            Header: 'Akcje',
            Cell: (props, nr) => 
            <div className="actions">
            {(props.original.locality).length == 0 &&
                <div className="ico error" onClick={ () => this.setState({removeWindow: 1, removeData: props.original, removeIndex: nr}) }>
                    <i className="material-icons">delete</i>
                </div>
            }
            </div>
        } 
      ]

        return (
          <div className={`column col-${ this.props.col }`}>
            { this.state.removeWindow ? 
              <div className="modal">
                <div className="icon">
                  <i className="material-icons">error_outline</i>
                </div> 
                <div className="exit" onClick={ () => this.setState({removeWindow: 0, removeUser: []}) }>
                  <i className="material-icons">highlight_off</i>
                </div>
                <div className="content">
                    <h2>OSTRZEŻENIE</h2>
                    <p>Czy aby na pewno chcesz usunąć grupę  <strong>{this.state.removeData.name}</strong> ?</p>
                    <p>Akcji nie będzie można cofnąć!</p>
                    <div className="btn-wrap">
                        <div className="col-sm-4">
                            <div className="btn" onClick={ () => this.removeGroup() }>
                                <i className="material-icons">delete_outline</i>
                                Usuń
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              :
              null
            } 
            <ReactTable
              data={this.state.groups}
              columns={columns}
              sortable={false}
              showPageSizeOptions={false}
              defaultPageSize={20}
              showPagination={true}
              filterable={true}
            />
          </div>
        )
    }
}