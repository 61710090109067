import React from 'react';
import logout from '../../../functions/user/logout';
import { useSelector, useDispatch } from 'react-redux'

const Header = (props) => {
    const dispatch = useDispatch();
    let lang = useSelector(state => state.language);
    let langArray = ['PL', 'CZ'];

    const changeLanguage = (newLang) => {
        dispatch({ type: 'LANG', value: newLang })
    }
    return (
        <header>
            <nav>
                <ul>
                    <li>
                        <select onChange={(e) => changeLanguage(e.target.value)}>
                            {langArray.map((item, index) => (
                                <option key={index} selected={item == lang ? "selected" : ""}>{item}</option>
                            ))}
                        </select>
                    </li>
                    <li>
                        Witaj,
                        <span> {props.user && props.user.login} </span>
                    </li>
                    <li>
                        <a onClick={ () => logout(props) } href="#">
                            <i className="material-icons">power_settings_new</i>
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
