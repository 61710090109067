export const onStart = (state = [], action) => { 
    switch (action.type) {
        case 'USER_DATA':
            return action.value;      
        default:
            return state
    }
}

export const language = (state = 'PL', action) => { 
    switch (action.type) {
        case 'LANG':
            return action.value;      
        default:
            return state
    }
}