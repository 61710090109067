import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Input from '../components/Input';
import Textarea from '../components/Textarea';
import Select from '../components/Select';
import ImageRepeater from './../components/ImageRepeater';
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import avatar from './../../../img/avatar.png'
import convertBase64 from './../components/convertBase64';
import VrRepeater from './../components/VrRepeater';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

const EditAtraction = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ name, setName ] = useState('');
    let [ description, setDescription ] = useState('');
    let [ shortDescription, setShortDescription ] = useState('');
    let [ lat, setLat ] = useState('');
    let [ lng, setLng ] = useState('');
    let [ languageLocal, setLanguageLocal ] = useState('PL');
    let [ gallery2d, setGallery2d ] = useState('');
    let [ mainImage, setMainImage ] = useState(['']);
    let [ mainImageName, setMainImageName ] = useState('');
    let [ mainImageDescription, setMainImageDescription ] = useState('');
    let [ gallery360, setGallery360 ] = useState('');
    let [ activeVr, setActiveVr ] = useState(99999999);

    let [ errorName, setErrorName ] = useState(0);
    let [ errorDescription, setErrorDescription ] = useState(0);
    let [ errorShortDescription, setErrorShortDescription ] = useState(0);
    let [ errorLat, setErrorLat ] = useState(0);
    let [ errorLng, setErrorLng ] = useState(0);
    let [ errorLanguageLocal, setErrorLanguageLocal ] = useState(0);
    let [ errorMainImage, setErrorMainImage ] = useState(0)
    let [ errorMainImageName, setErrorMainImageName ] = useState(0);
    let [ errorMainImageDescription, setErrorMainImageDescription ] = useState(0);

    //data
    let atractionID = typeof window !== "undefined" && (window.location.pathname).split("/");atractionID = atractionID[atractionID.length -1]
    let languages = [
        {
            value: "PL",
            text: "Polski"
        }, 
        {
            value: "CZ",
            text: "Czeski"
        }
    ]

    useEffect(() => {
        if(!load) {
            getGroups();
            getCity();
            setLoad(true);
        }
    }, [load])

    const getCity = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getAtractionByID`,
            data: {
                token: localStorage.getItem('token'),
                access_token: localStorage.getItem('token'),
                atractionID: atractionID
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);res = res.data[0];
            setName(res.name);
            setDescription(res.description);
            setShortDescription(res.short_description);
            setLat(res.map_lat);
            setLng(res.map_lng);
            setLanguageLocal(res.language)
            setMainImage([{
                src: res.main_image_id ? res.main_image_id.src : '',
                id: res.main_image_id ? res.main_image_id.id : '',
                update: false,
            }])
            setMainImageName(res.main_image_id ? res.main_image_id.name : '');
            setMainImageDescription(res.main_image_id ? res.main_image_id.description : '')
            let newGallery2d = [];
            res.gallery_2d_ids && res.gallery_2d_ids.map(img => {
                newGallery2d = [...newGallery2d, {
                    file: img.src,
                    id: img.id,
                    name: img.name,
                    description: img.description,
                    visible: img.visible,
                    update: false
                }]
            })
            setGallery2d(newGallery2d);
            let newGallery360 = [];
            res.gallery_3d_ids && res.gallery_3d_ids.map(img360 => {
                newGallery360 = [...newGallery360, {
                    id: img360.id,
                    name: img360.name,
                    order: img360.order,
                    remove: false
                }]
            })
            newGallery360 = newGallery360.sort((a,b) => a.order - b.order);
            setGallery360(newGallery360);
        })
    }

    const getGroups = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getLanguagesGroup`,
            data: {
                token: localStorage.getItem('token'),
                access_token: localStorage.getItem('token'),
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            let data = [];
            res.data.map(item => {
                data = [...data, {
                    value: item.id,
                    text: item.name
                }]
            })
        })
    }

    const clearErrors = () => {
        setErrorName(0);
        setErrorDescription(0);
        setErrorShortDescription(0);
        setErrorLat(0);
        setErrorLng(0);
        setErrorLanguageLocal(0);
        setErrorMainImage(0);
    }

    const saveLocality = async() => {
        setBlockedBtn(true);
        clearErrors();
        let errors = 0;

        if(name.length == 0) {
            setErrorName(1);
            errors = 1;   
        }
        if(description.length == 0) {
            setErrorDescription(1);
            errors = 1;   
        }
        if(shortDescription.length == 0) {
            setErrorShortDescription(1);
            errors = 1;   
        }
        // if(lat.length == 0) {
        //     setErrorLat(1);
        //     errors = 1;   
        // }
        // if(lng.length == 0) {
        //     setErrorLng(1);
        //     errors = 1;   
        // }
        if(languageLocal.length == 0) {
            setErrorLanguageLocal(1);
            errors = 1;   
        }
        if(mainImage.length == 0) {
            setErrorMainImage(1);
            errors = 1;   
        }
        if(mainImageName.length == 0) {
            setErrorMainImageName(1);
            errors = 1;   
        }
        if(mainImageDescription.length == 0) {
            setErrorMainImageDescription(1);
            errors = 1;   
        }

        if(errors === 1) {
            setBlockedBtn(false);
        }
        
        if(errors === 0) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/saveNewAtraction`,
                data: {
                    token: localStorage.getItem('token'),
                    access_token: localStorage.getItem('token'),
                    name: name,
                    description: description,
                    shortDescription: shortDescription,
                    lat: lat,
                    lng: lng,
                    languageLocal: languageLocal,
                    gallery2d: gallery2d,
                    gallery360: gallery360,
                    mainImage: {
                        image: mainImage,
                        name: mainImageName,
                        description: mainImageDescription
                    },
                    userID: localStorage.getItem('userId'),
                    edit: true,
                    atractionID: atractionID
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == "success") {
                    setBlockedBtn(false);
                    alert("Zaaktualizowano !")
                }else{
                    setBlockedBtn(false);
                    alert("Coś poszło nie tak, prosimy spróbować ponownie później.")
                }
            })
        }
    }

    const saveMainImage = async(e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setMainImage([{
            src: base64,
            id: mainImage[0].id,
            update: true,
        }])
    }

    const handleRLDDChange = (newItems) => {
        setGallery360(newItems);
    }

    return (
        <>  
            <div className="section-back">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to="/dashboard/atractions">Powrót</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card card-data">
                            <div className="card-header">
                                <div className="card-title card-over">
                                    <h5>Edytuj atrakcję: </h5>
                                </div>
                            </div>
                            <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-sm-12 box-wrapper">
                                        <div className="box">
                                            <div className="row">
                                                <Input name="name"
                                                    label="Nazwa"
                                                    type="text" 
                                                    value={ name }
                                                    disabled={ false } 
                                                    handle={ (val) => setName(val) } 
                                                    error={errorName}
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Textarea name="description"
                                                    label="Opis"
                                                    value={ description }
                                                    disabled={ false } 
                                                    handle={ (val) => setDescription(val) } 
                                                    error={ errorDescription }
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Textarea name="description"
                                                    label="Krótki opis"
                                                    value={ shortDescription }
                                                    disabled={ false } 
                                                    handle={ (val) => setShortDescription(val) } 
                                                    error={ errorShortDescription }
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Input name="lat"
                                                    label="Położenie lat"
                                                    type="text" 
                                                    value={ lat }
                                                    disabled={ false } 
                                                    handle={ (val) => setLat(val) } 
                                                    error={errorLat}
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Input name="lng"
                                                    label="Położenie lng"
                                                    type="text" 
                                                    value={ lng }
                                                    disabled={ false } 
                                                    handle={ (val) => setLng(val) } 
                                                    error={errorLng}
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <Select name="lng"
                                                    label="Język w jakim wprowadzamy miejscowość"
                                                    data={ languages } 
                                                    value={ languageLocal }
                                                    handle={ (val) => setLanguageLocal(val) } 
                                                    error={errorLanguageLocal}
                                                    column="xs-12" />
                                            </div>
                                            <div className="row">
                                                <div className="repeaters">
                                                    {load && gallery2d && gallery2d.map((item, i) => (
                                                        <ImageRepeater data={item} number={i} showNewInputs={gallery2d} setNewInputs={ (newObject) => setGallery2d(newObject) } withData={true} />
                                                    ))}
                                                </div>
                                                <div className="btn" style={{ margin: '0 15px' }} onClick={ () => { setGallery2d([...gallery2d, '']); } }>
                                                    <i className="material-icons">add</i>
                                                    Dodaj kolejne zdjęcie 2d
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <div className="box">
                                            <div className="col-sm-12">
                                                <div className="avatar">
                                                    <img className={errorMainImage ? "error" : ""} src={ mainImage[0].src ? mainImage[0].src : avatar } />
                                                </div>
                                                <div className="upload-column">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn">Wgraj plik</button>
                                                        <input type="file" name="myfile" onChange={(e) => saveMainImage(e)} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <Input name="imageName"
                                                        label="Nazwa zdjęcia"
                                                        type="text" 
                                                        value={ mainImageName }
                                                        disabled={ false } 
                                                        handle={ (val) => setMainImageName(val) } 
                                                        error={ errorMainImageName }
                                                        column="xs-12" />
                                                </div>
                                                <div className="row">
                                                    <Textarea name="description"
                                                        label="Opis zdjęcia"
                                                        value={ mainImageDescription }
                                                        disabled={ false } 
                                                        handle={ (val) => setMainImageDescription(val) } 
                                                        error={ errorMainImageDescription }
                                                        column="xs-12" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vr-box">
                                    {/* {load && gallery360 && gallery360.map((item, i) => (
                                        <div className="wrap-vr">
                                            <span className="header" onClick={() => setActiveVr(i == activeVr ? 99999999 : i)}>{item.name}</span>
                                            {activeVr == i &&
                                            <VrRepeater dataID={atractionID} data={item} number={i} showNewInputs={gallery360} setNewInputs={ (newObject) => setGallery360(newObject) } type={"atractionID"} />
                                            }
                                        </div>
                                    ))} */}
                                    {load && gallery360 &&
                                        <RLDD
                                            items={gallery360}
                                            itemRenderer={(item, i) => {
                                                return (
                                                    <div className="wrap-vr">
                                                        <span className="header" onClick={() => setActiveVr(i == activeVr ? 99999999 : i)}>{item.name}</span>
                                                        {activeVr == i &&
                                                        <VrRepeater dataID={atractionID} data={item} number={i} showNewInputs={gallery360} setNewInputs={ (newObject) => setGallery360(newObject) } type={"atractionID"} />
                                                        }
                                                    </div>
                                                );
                                            }}
                                            onChange={(val) => handleRLDDChange(val)}
                                        />
                                    }
                                </div>
                                <div className="btn" onClick={ () => { setGallery360([...gallery360, '']); } }>
                                    <i className="material-icons">add</i>
                                    Dodaj kolejne zdjęcie VR
                                </div>
                                <div className="col-sm-12" style={{ padding: "0" }}>
                                    <div className={blockedBtn ? "btn disable" : "btn"} onClick={ () => { !blockedBtn && saveLocality() } }>
                                        Zaaktualizuj atrakcje
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>        
    );
}

export default EditAtraction;
