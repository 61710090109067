import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
// import CustomPagination from './customPagination';

let results = [];
let lang = 'PL';
export default class AtractionsTable extends Component {
    async componentDidMount() {
      if(results.length === 0) {
        await this.getAtractions();
        lang = this.props.lang;
      }else{
        this.setState({
            atractions: results
        })
      } 
    }

    async componentDidUpdate() {
      if(this.props.lang !== lang) {
        lang = this.props.lang;
        await this.getAtractions();
      }
    }

    constructor(props) {
      super(props)

      this.state = {
        atractions: [],
      }
    }

    async getAtractions() {
      this.setState({
        atractions: []
      })
      await axios({
          method: 'post',
          url: `${ApiUrl()}/atractionsList`,
          data: {
            token: localStorage.getItem('token'),
            access_token: localStorage.getItem('token'),
            lang: this.props.lang
          }
      }).then(response => {
          results = response.data.data;
          console.log(response)
      });
      console.log(results)
      results.map(item => {
        this.setState(prevState => ({
            atractions: [...prevState.atractions, { 
            id                : item['id'],
            main_image_id     : item['main_image_id'] !== null ? item['main_image_id']['src'] : null,
            name              : item['name'],
            description       : item['description'],
            shortDescription  : item['short_description'],
            map_lat           : item['map_lat'],
            map_lng           : item['map_lng'],
            user_id           : item['user_id'],
            created_at        : item['created_at'],
          }]
        }))
      })
    }

    render() {
        const columns = [
        {
          Header: 'ID',
          accessor: 'id'
        },
        {
          Header: 'Główne zdjęcie',
          Cell: props => <img src={ props.original.main_image_id } />,
        },
        {
          Header: 'Nazwa',
          accessor: 'name',
        },
        {
          Header: 'Opis',
          accessor: 'shortDescription',
        },
        {
          Header: 'Mapa koordynat LAT',
          accessor: 'map_lat',
        },
        {
          Header: 'Mapa koordynat LNG',
          accessor: 'map_lng',
        },
        {
          Header: 'Dodał',
          Cell: props => <div> { props.original.user_id.login } </div>,
        }   
      ]

        return (
          <div className={`column col-${ this.props.col }`}>
            <ReactTable
              data={this.state.atractions}
              columns={columns}
              sortable={false}
              getTrProps={(state, rowInfo) => ({
                onClick: () => this.props.This.history.push("atractions/atraction/" + rowInfo.original.id)
              })}
              showPageSizeOptions={false}
              defaultPageSize={20}
              showPagination={true}
              filterable={true}
            />
          </div>
        )
    }
}