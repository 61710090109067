import React, { useState,useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import '../../scss/dashboard.scss';
import Header from './partials/Header'
import Navbar from './partials/Navbar'
import Welcome from './pages/Welcome';
import Users from './pages/Users';
import User from './pages/User';
import MessagePush from './pages/MessagePush';
import Locals from './pages/Locals';
import ImportLocals from './pages/ImportLocals';
import Translate from './pages/Translate';
import Reviews from './pages/Reviews';
import Slider from './pages/Slider';
import Trade from './pages/Trade';
import newLocal from './pages/newLocal';
import GroupLanguages from './pages/GroupLanguages';
import Atractions from './pages/Atractions';
import newAtraction from './pages/newAtraction';
import Funding from './pages/Funding';
import MapSettings from './pages/MapSettings';
import EditLocal from './pages/EditLocal';
import EditAtraction from './pages/EditAtraction';
import ApiUrl from './../../services/ApiUrl';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import refreshToken from './../../services/refreshToken';

let userData = [];
const DashboardView = (props) => {
    // let userDataRedux = useSelector(state => state.onStart);

    // useEffect(() => {
    //     setFetch(1);
    // }, [userDataRedux])
    const dispatch = useDispatch();
    let [load, setLoad] = useState(0);
    
    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await sessionUser();
                await getUser();
                await setLoad(1);
            })()
        }
    },[load]);

    // if(load && typeof window !== "undefined") {
    //     setTimeout(
    //         function() {
    //             refreshToken();
    //         }
    //         .bind(this),
    //         2000
    //     );
    // }

    const sessionUser = async () => {
        var currentUser = localStorage.getItem('token') ? true : false;
        if (!currentUser) {
            props.history.push({ pathname: '/login' });
        }
    }

    const getUser = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/me`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }

        }).then(response => {
            let res = JSON.parse(response.request.response);
            userData = res;
            dispatch({ type: 'USER_DATA', value: userData })
        });
    }

    return (
        <>
            <div className="wrapper">
                <Navbar />
                <main>
                    <Header props={props} user={userData} />
                    <Switch>
                        <Route path={`/dashboard`} exact component={ Welcome } />
                        <Route path={`/dashboard/users`} exact component={ Users } />
                        <Route path={`/dashboard/users/:id`} component={ User } />
                        <Route path={`/dashboard/localities`} exact component={ Locals } />
                        <Route path={`/dashboard/localities/city/:id`} exact component={ EditLocal } />
                        <Route path={`/dashboard/localities/new`} exact component={ newLocal } />
                        <Route path={`/dashboard/localities/group-languages`} exact component={ GroupLanguages } />
                        <Route path={`/dashboard/translate`} exact component={ Translate } />
                        {/* <Route path={`/dashboard/map-settings`} exact component={ MapSettings } /> */}
                        <Route path={`/dashboard/atractions`} exact component={ Atractions } />
                        <Route path={`/dashboard/atractions/atraction/:id`} exact component={ EditAtraction } />
                        <Route path={`/dashboard/atractions/new`} exact component={ newAtraction } />
                        <Route path={`/dashboard/slider`} exact component={ Slider } />
                    </Switch>
                </main>
            </div>
        </>
    );
}

export default DashboardView;