import React, { useState, useEffect } from 'react'

const SelectItems = ( props ) => {
    let [ selected, setSelected ] = useState([]);


    useEffect(() => {
        if(props.value && (props.value).length > 0) {
            setSelected(props.value)
        }
    }, [props.value])

    const addSelected = (item) => {
        let status = true;
        selected && selected.filter(sel => {
            if(sel.id == item.id) {
                status = false;
            } 
        })

        if(status) {
            setSelected([...selected, item])
            props.handle([...selected, item])
        }
    }

    const removeSelected = (item) => {
        let newSelected = selected.filter(sel => {
            if(sel.id !== item.id) {
                return sel;
            }
        })
        setSelected(newSelected)
        props.handle(newSelected)
    }

    const checkClass = (atraction) => {
        let newClass = ""
        selected.filter(item => {
            if(item.id == atraction.id) {
                newClass = "disabled";
            }
        })
        return newClass;
    }

    return (
        <div className={ `col-${ props.column } select-items` } >
            <div className="form-group bmd-form-group">
                <label className={props.error ? "bmd-label-floating error" : "bmd-label-floating"} > { props.label } </label>
                <div className="box">
                    <div className="col">
                        {props.data && props.data.map((atraction, index) => (
                            <span className={checkClass(atraction)} key={index} onClick={ () => addSelected(atraction)}>{ atraction.name }</span>
                        ))}
                    </div>
                    <div className="col">
                        {selected && selected.map((sel, i) => (
                            <span key={i} onClick={ () => removeSelected(sel)}>{ sel.name }</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>   
    );
}

export default SelectItems;
