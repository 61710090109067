import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import GroupLanguagesTable from './../components/table/GroupLanguagesTable';
import Input from '../components/Input'
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'

const GroupLanguages = (props) => {
    let [ load, setLoad ] = useState(0);
    let [ name, setName ] = useState('');
    let [ errorName, setErrorName ] = useState(0);
    let [ groups, setGroups ] = useState([]);
    let [ newGroup, setNewGroup ] = useState(false);
    let userData = useSelector(state => state.onStart);

    const save = () => {
        setErrorName(0);
        let errors = 0;

        if(name.length == 0) {
            setErrorName(1);
            errors = 1;   
        }
        if(groups.length > 0) {
            groups.map(group => {
                if(group.name == name) {
                    setErrorName(1);
                    errors = 1;   
                    alert("Taka nazwa grupy jest już używana");
                }
            })
        }

        if(errors === 0) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/addLanguagesGroup`,
                data: {
                    userID: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                    name: name
                }
            }).then(response => {
                setNewGroup({
                    'created_at': '',
                    'id': response.data.id,
                    'locality': [],
                    'name': name,
                    'updated_at': '',
                    'user_id': userData
                })
                setName('');
                alert("Dodano nową grupę");
            }).catch(e => {
                alert("Coś poszło nie tak, prosimy spróbować później");
            })
        }
    }

    return (
        <>  
            <div className="section-back">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to="/dashboard/localities">Powrót</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card card-data">
                            <div className="card-header">
                                <div className="card-title card-over">
                                    <h5>Dodaj nową grupę językową: </h5>
                                </div>
                            </div>
                            <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <Input name="name"
                                            label="Nazwa"
                                            type="text" 
                                            value={ name }
                                            disabled={ false } 
                                            handle={ (val) => setName(val) } 
                                            error={errorName}
                                            column="xs-12" />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="btn" onClick={ () => save() } > Utwórz </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <GroupLanguagesTable col="xs-12" This={props} saveGroups={(data) => setGroups(data)} newGroup={newGroup} setNewGroup={() => setNewGroup(false)} />
            </div>
          
        </>        
    );
}

export default GroupLanguages;
