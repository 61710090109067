import React, { useState, useEffect } from 'react'

const NewTranslateInput = ( props ) => {
    let [ key, setKey ] = useState();
    let [ pl, setPl ] = useState();
    let [ cz, setCz ] = useState();
    let newArray = [];
    let [ load, setLoad ] = useState(0);

    useEffect(() => {
        if(load === 0) {
            setKey(props.data.key ? props.data.key : '');
            setPl(props.data.pl ? props.data.pl : '');
            setCz(props.data.cz ? props.data.cz : '');
            setLoad(1)
        }
    }, [load])

    const createObject = () => {
        newArray = props.showNewInputs;
        newArray[props.number] = {                    
            key: key,
            pl: pl,
            cz: cz,
            id: props.data.id ? props.data.id : 0,
        }
        props.setNewInputs(newArray);
    }
    
    return (
        <div className="input-wrapper">
            <div className="number">
                <span>{ props.number +1 }</span>
            </div>
            <div className="box">
                <div className="item">
                    <label>Klucz</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setKey(e.target.value) } value={ key } />
                </div>
            </div>
            <div className="box">
                <div className="item">
                    <label>Tłumaczenie PL</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setPl(e.target.value) } value={ pl } />
                </div>
                <div className="item">
                    <label>Tłumaczenie CZ</label>
                    <input type="text" onBlur={ () => createObject() } onChange={ (e) => setCz(e.target.value) } value={ cz } />
                </div>
            </div>
        </div> 
    );
}

export default NewTranslateInput;
